<script>
import QualityScoresService from "@/api/services/qualitySocreInfo.service";
import appConfig from "@/app.config";
import i18n from "@/i18n";
import {
MAXIMUM_NUMBER_OF_CREDITS_FOR_IBS,
MAXIMUM_NUMBER_OF_CREDITS_PER_RESPONDENT,
MINIMU_NUMBER_OF_CREDITS_PER_RESPONDENT
} from "../util/const.js";

export default {
  page: {
    title: "Credit & Cost Calculator",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {},
  data() {
    return {
      /****** Credit Calculator Variable *****/
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
      /**** Information for Calculation ****/
      qualityScoreInfo: null,
      qualityScores: {
        OES: { isEnable: true, qty: 2 },
        IBS: { isEnable: true, qty: 15 },
        TS: { isEnable: true, qty: 1 },
        PQ: { isEnable: false, qty: null }
      },
      packages: [
        { name: "Starter", pricePerCredit: 0.04, priceOfPackage: 2000, includedCredits: 50000, includedNumberOfInterviews: 12500, pricePerInterview: 0, totalYearlyAmout: 13000, addtionalPayment:0, isRecommended: false, isSelected: false},
        { name: "Professional", pricePerCredit: 0.02, priceOfPackage: 10000, includedCredits: 500000, includedNumberOfInterviews: 125000, pricePerInterview: 0, totalYearlyAmout: 10000, addtionalPayment:0, isRecommended: false, isSelected: false},
        { name: "Enterprise", pricePerCredit: 0.01, priceOfPackage: 20000, includedCredits: 2000000, includedNumberOfInterviews: 500000, pricePerInterview: 0, totalYearlyAmout: 200000, addtionalPayment:0, isRecommended: false, isSelected: false},
      ],
      /***** User Inputs *****/
      numberOfInterviewsPlanedPerYear: 50000,
      numberOfProjectsPlanedPerYear: null,
      numberOfHoursSpentPerProject: 5,
      costPerHour: 80,
      selectedPackage: null,
      /**** Calculated Values ****/
      totalCreditAmountNeeded: 0,
      neededCreditPerRespondent: 0,
      totalRedemCostPerYear: 0,
      /**** Operational Variables ****/
      oneOfThePackagesSelected: false,
      displayROI: false,
      displayYourSituation: false,
      /****** ROI Calculator Variable *****/
      totalInternalCostPerYear: 0,
      internalCostPerProject: 0,
      redemCostPerProject: 0,
      costSavingPerYear: 0,
      costSavingPerYearPercentage: 0,
      costSavingPerProject: 0,
      /****** Const ******/
      numberOfRespondentsForAverageProject: 1000
    };
  },
  watch: {
    qualityScores: {
      handler() {
        this.Initialcalculation();
      },
      deep: true
    },
    numberOfInterviewsPlanedPerYear() {
      this.Initialcalculation();
    }
  },
  computed: {
    propoverMethodMaximumCreditPerRespondent() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t(
            "creditAndCostCalculator.creditCalculator.maximumCreditPerRespondent.description.point1"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "creditAndCostCalculator.creditCalculator.maximumCreditPerRespondent.description.point2"
          ) +
          "</li> <br/> <li>" +
          "<a href='https://help.redem.io/docs/data-import/redem-credits/' target='_blank'>" +
          this.$i18n.t(
            "creditAndCostCalculator.creditCalculator.maximumCreditPerRespondent.description.moreInfo"
          ) +
          "</a>" +
          "</li> </ul>"
      };
    },
    propoverMethodTimeSpentForEvaluation() {
      return {
        html: true,
        content: () =>
          this.$i18n.t(
            "creditAndCostCalculator.currentSituation.timeToSpent.description"
          )
      };
    },
    propoverMethodEstimatedCostPerHour() {
      return {
        html: true,
        content: () =>
          this.$i18n.t(
            "creditAndCostCalculator.currentSituation.costPerHour.description"
          )
      };
    }
  },
  async mounted() {
    await this.getQualityScoreInfo();
    await this.Initialcalculation();
  },
  methods: {
    async getQualityScoreInfo() {
      try {
        this.qualityScoreInfo =
          await QualityScoresService.getAllQualityScoreInfo();
      } catch (e) {
        console.log("Fetching Quality Scores Having an Error", e);
      }
    },
    async Initialcalculation() {
      // 0. Set the selected package to null
      this.selectedPackage = null;
      this.oneOfThePackagesSelected = false;

      // 1. Set value to neededCreditPerRespondent
      await this.setValueToCreditPerRespondent();

      // 2. set value to totalCreditAmountNeeded
      await this.setValueToTotalCreditAmountNeeded();

      // 3. set valuse to credit comparison table
      await this.setValuesToCreditComparisonTable();

      // 4. Set the recommended Package
      await this.setRecommenedPackage();

      this.selectedPackage = this.packages.find(p => p.isRecommended);
      this.oneOfThePackagesSelected = true;
    },
    setValueToCreditPerRespondent() {
      this.neededCreditPerRespondent = 0;
      let qualityScorePrices = {
        TS: 0,
        OES: 0,
        IBS: 0,
        PQ: 0
      };

      for (let i = 0; i < this.qualityScoreInfo.length; i++) {
        // Calculate Credti For TS
        if (
          this.qualityScoreInfo[i].code === "TS" &&
          this.qualityScores.TS.isEnable
        ) {
          qualityScorePrices.TS += this.qualityScoreInfo[i].creditPrice;
        }

        // Calculate Credti For IBS
        if (
          this.qualityScoreInfo[i].code === "IBS" &&
          this.qualityScores.IBS.isEnable &&
          this.qualityScores.IBS.qty > 0
        ) {
          qualityScorePrices.IBS +=
            this.qualityScores.IBS.qty * this.qualityScoreInfo[i].creditPrice;

          if (qualityScorePrices.IBS > MAXIMUM_NUMBER_OF_CREDITS_FOR_IBS)
            qualityScorePrices.IBS = MAXIMUM_NUMBER_OF_CREDITS_FOR_IBS;
        }

        // Calculate Credti For OES
        if (
          this.qualityScoreInfo[i].code === "OES" &&
          this.qualityScores.OES.isEnable &&
          this.qualityScores.OES.qty > 0
        ) {
          let OESCount = this.qualityScores.OES.qty;
          // count credit for first OES Data point
          qualityScorePrices.OES += this.qualityScoreInfo[i].creditPrice;
          OESCount -= 1;

          // count credit for other OES Data point
          qualityScorePrices.OES +=
            OESCount *
            this.qualityScoreInfo[i].creditPriceForAdditionalDataPoint;
        }

        // Calculate Credti For PQ
        if (
          this.qualityScoreInfo[i].code === "PQ" &&
          this.qualityScores.PQ.isEnable &&
          this.qualityScores.PQ.qty > 0
        ) {
          let PQCount = this.qualityScores.PQ.qty;
          // count credit for first OES Data point
          qualityScorePrices.PQ += this.qualityScoreInfo[i].creditPrice;
          PQCount -= 1;

          // count credit for other OES Data point
          qualityScorePrices.PQ +=
            PQCount *
            this.qualityScoreInfo[i].creditPriceForAdditionalDataPoint;
        }
      }

      // calculcate final credit price per respondent
      this.neededCreditPerRespondent =
        qualityScorePrices.TS +
        qualityScorePrices.IBS +
        qualityScorePrices.OES +
        qualityScorePrices.PQ;

      if (this.neededCreditPerRespondent > MAXIMUM_NUMBER_OF_CREDITS_PER_RESPONDENT)
        this.neededCreditPerRespondent = MAXIMUM_NUMBER_OF_CREDITS_PER_RESPONDENT;
      if (this.neededCreditPerRespondent < MINIMU_NUMBER_OF_CREDITS_PER_RESPONDENT)
        this.neededCreditPerRespondent = MINIMU_NUMBER_OF_CREDITS_PER_RESPONDENT;
    },
    setValueToTotalCreditAmountNeeded() {
      this.totalCreditAmountNeeded =
        this.numberOfInterviewsPlanedPerYear * this.neededCreditPerRespondent;
    },
    setValuesToCreditComparisonTable() {
      // set all into initial state
      this.packages.forEach(creditPackage => {
        creditPackage.pricePerInterview = 0;
        creditPackage.totalYearlyAmout = 0;
        creditPackage.addtionalPayment = 0;
        creditPackage.isRecommended = false;
      });

      this.packages.forEach(creditPackage => {
        // calculate total yearly payment
        let totalYearlyPayment = this.totalCreditAmountNeeded*creditPackage.pricePerCredit;
        creditPackage.totalYearlyAmout = (totalYearlyPayment <= creditPackage.priceOfPackage) ? creditPackage.priceOfPackage : this.totalCreditAmountNeeded*creditPackage.pricePerCredit;

        // calculate addtional payment 
        creditPackage.addtionalPayment = creditPackage.totalYearlyAmout - creditPackage.priceOfPackage;

        // calculate price per interview
        if(creditPackage.includedCredits <= this.totalCreditAmountNeeded) {
          creditPackage.pricePerInterview = this.neededCreditPerRespondent*creditPackage.pricePerCredit;
        } else {
          creditPackage.pricePerInterview = creditPackage.totalYearlyAmout/this.numberOfInterviewsPlanedPerYear;
        }

      });
    },
    setRecommenedPackage() {
      let cheapestPackage = this.packages[0];

      this.packages.forEach(creditPackage => {
        if (creditPackage.totalYearlyAmout < cheapestPackage.totalYearlyAmout) {
          cheapestPackage = creditPackage;
        }
        creditPackage.isRecommended = false;
        creditPackage.isSelected = false;
      });

      cheapestPackage.isRecommended = true;
      cheapestPackage.isSelected = true;
    },
    async ROICalculation() {
      await this.setValueToWithRedem();
      await this.setValueToWithoutRedem();
      await this.setValuesToCostSaving();
    },  
    setValueToWithRedem() {
      // this.totalRedemCostPerYear = this.selectedPackage.pricePerInterview * this.numberOfInterviewsPlanedPerYear;
      console.log(this.selectedPackage);
      this.totalRedemCostPerYear = this.selectedPackage.totalYearlyAmout;
      this.redemCostPerProject = this.totalRedemCostPerYear / this.numberOfProjectsPlanedPerYear;
    },
    setValueToWithoutRedem() {
      // calculate totalInternCostPerYear
      this.totalInternalCostPerYear =
        this.numberOfProjectsPlanedPerYear *
        this.numberOfHoursSpentPerProject *
        this.costPerHour;

      // calculate internalCostPerProject
      this.internalCostPerProject =
        this.numberOfHoursSpentPerProject * this.costPerHour;
    },
    setValuesToCostSaving() {
      // calculate costSavingPerYear
      this.costSavingPerYear =
        this.totalInternalCostPerYear - this.totalRedemCostPerYear;

      // calculate costSavingPerYearPercentage
      this.costSavingPerYearPercentage =
        (this.costSavingPerYear / this.totalInternalCostPerYear) * 100;

      // calculate costSavingPerProject
      this.costSavingPerProject =
        this.internalCostPerProject - this.redemCostPerProject;
    },
    setPricePerRespondent() {
      this.selectedPackage.pricePerInterview = this.neededCreditPerRespondent * this.pricePerCredit;
    },
    async clickDisplayROI() {
      await this.ROICalculation();
      this.displayROI = true;
    },
    clickHideROI() {
      this.displayROI = false;
    },
    clickDisplayYourSituation() {
      this.displayYourSituation = true;
    },
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    },
    clickMailToButton() {
      window.location.assign("mailto:credits@redem.io");
    },
    selectCreditPackage(creditPackageIndex) {
      this.selectedPackage = null;
      this.oneOfThePackagesSelected = true;
      for(let i=0; i < this.packages.length; i++) {
        if(i===creditPackageIndex){
          this.packages[i].isSelected = true;
          this.selectedPackage = this.packages[i];
        } else {
          this.packages[i].isSelected = false;
        }
      }
    }
  }
};
</script>

<template>
  <div class="p-3">
    <!-- Credit Calculator -->
    <div class="row justify-content-center">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <!-- Number Of Interviews Planed Per Year -->
            <div>
              <label>
                {{
                  $t(
                    "creditAndCostCalculator.creditCalculator.numberofRespondentsPerYear.label"
                  )
                }}:
              </label>
              <b-form-input
                v-model.number="numberOfInterviewsPlanedPerYear"
                type="number"
                :placeholder="
                  $t(
                    'creditAndCostCalculator.creditCalculator.numberofRespondentsPerYear.placeholder'
                  )
                "
                :disabled="displayROI"
                :style="[
                  displayROI
                    ? { background: '#f5f5f5' }
                    : { background: '#fff' }
                ]"
              >
              </b-form-input>
            </div>

            <!-- Planed Quality Scores -->
            <div class="mt-4">
              <label>
                {{
                  $t(
                    "creditAndCostCalculator.creditCalculator.qualityChecks.title"
                  )
                }}
              </label>
              <!-- TS -->
              <div class="row align-items-center mt-2 ml-2">
                <div class="col-1">
                  <input
                    class="text-primary"
                    type="checkbox"
                    v-model="qualityScores.TS.isEnable"
                    :disabled="displayROI"
                  />
                </div>
                <div class="col-11">
                  <label>
                    {{
                      $t(
                        "creditAndCostCalculator.creditCalculator.qualityChecks.types.timestamps"
                      )
                    }}
                  </label>
                </div>
              </div>
              <!-- OES -->
              <div class="row align-items-center mt-2 ml-2">
                <div class="col-1">
                  <input
                    class="text-primary"
                    type="checkbox"
                    v-model="qualityScores.OES.isEnable"
                    :disabled="displayROI"
                  />
                </div>
                <div class="col-7">
                  <label>
                    {{
                      $t(
                        "creditAndCostCalculator.creditCalculator.qualityChecks.types.openEnded"
                      )
                    }}
                  </label>
                </div>
                <div class="col-4">
                  <b-form-input
                    type="number"
                    :placeholder="
                      $t(
                        'creditAndCostCalculator.creditCalculator.qualityChecks.placeholderForNumberOfChecks'
                      )
                    "
                    v-model="qualityScores.OES.qty"
                    :disabled="displayROI"
                    :style="[
                      displayROI
                        ? { background: '#f5f5f5' }
                        : { background: '#fff' }
                    ]"
                  >
                  </b-form-input>
                </div>
              </div>
              <!-- IBS -->
              <div class="row align-items-center mt-2 ml-2">
                <div class="col-1">
                  <input
                    class="text-primary"
                    type="checkbox"
                    v-model="qualityScores.IBS.isEnable"
                    :disabled="displayROI"
                  />
                </div>
                <div class="col-7">
                  <label>
                    {{
                      $t(
                        "creditAndCostCalculator.creditCalculator.qualityChecks.types.itemBatteries"
                      )
                    }}
                  </label>
                </div>
                <div class="col-4">
                  <b-form-input
                    type="number"
                    :placeholder="
                      $t(
                        'creditAndCostCalculator.creditCalculator.qualityChecks.placeholderForNumberOfChecks'
                      )
                    "
                    v-model="qualityScores.IBS.qty"
                    :disabled="displayROI"
                    :style="[
                      displayROI
                        ? { background: '#f5f5f5' }
                        : { background: '#fff' }
                    ]"
                  >
                  </b-form-input>
                </div>
              </div>
              <!-- PQ -->
              <div class="row align-items-center mt-2 ml-2">
                <div class="col-1">
                  <input
                    class="text-primary"
                    type="checkbox"
                    v-model="qualityScores.PQ.isEnable"
                    :disabled="displayROI"
                  />
                </div>
                <div class="col-7">
                  <label>
                    {{
                      $t(
                        "creditAndCostCalculator.creditCalculator.qualityChecks.types.projectiveQuestions"
                      )
                    }}
                  </label>
                  <span>
                    <a
                      href="https://help.redem.io/docs/items-qualtiy-checks-questionnaire/projective-questions/general-info-projective-questions/"
                      target="_blank"
                    >
                      <i
                        class="ml-2 bx bxs-info-circle text-info"
                        style="cursor: pointer"
                      ></i>
                    </a>
                  </span>
                </div>
                <div class="col-4">
                  <b-form-input
                    type="number"
                    :placeholder="
                      $t(
                        'creditAndCostCalculator.creditCalculator.qualityChecks.placeholderForNumberOfChecks'
                      )
                    "
                    v-model="qualityScores.PQ.qty"
                    :disabled="displayROI"
                    :style="[
                      displayROI
                        ? { background: '#f5f5f5' }
                        : { background: '#fff' }
                    ]"
                  >
                  </b-form-input>
                </div>
              </div>
            </div>

            <!-- Needed Credits according to the selections  -->
            <div class="mt-4">
              <!-- Credits Per Interview -->
              <div class="row">
                <div class="col-8">
                  {{
                    $t(
                      "creditAndCostCalculator.creditCalculator.maximumCreditPerRespondent.label"
                    )
                  }}
                  <span>
                    <i
                      v-b-popover.hover="
                        propoverMethodMaximumCreditPerRespondent
                      "
                      class="ml-2 bx bxs-info-circle text-info"
                      style="cursor: pointer"
                    ></i
                  ></span>
                </div>
                <div class="col-4 text-right">
                  {{ neededCreditPerRespondent.toLocaleString("de-DE") }}
                </div>
              </div>

              <!-- Credti Per Year -->
              <div class="row mt-2">
                <div class="col-8">
                  {{
                    $t(
                      "creditAndCostCalculator.creditCalculator.maximumNumberOfCreditsPerYear"
                    )
                  }}
                </div>
                <div class="col-4 text-right">
                  {{ totalCreditAmountNeeded.toLocaleString("de-DE") }}
                </div>
              </div>
            </div>

            <!-- Package comparison -->
            <div class="mt-4">
              <div class="row">
                <div class="col-12">
                  <table class="table mt-3 mb-5">
                    <thead>
                      <tr>
                        <th></th>
                        <th v-for="(creditPackage, creditPackageIndex) in this.packages" :key="creditPackageIndex">
                          <input
                            v-model="creditPackage.isSelected"
                            type="radio"
                            :value="true"
                            checked
                            @input="selectCreditPackage(creditPackageIndex)"
                            :disabled="displayROI"
                          />
                          {{ creditPackage.name }} <span v-if="creditPackage.isRecommended"> <i class='bx bx-health bx-tada text-gold ml-2'></i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- credits from package -->
                      <tr>
                        <td> {{ $t( "creditAndCostCalculator.creditCalculator.includedCredits")}} </td>
                        <td v-for="(creditPackage, creditPackageIndex) in this.packages" :key="creditPackageIndex">
                          {{ creditPackage.includedCredits.toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}
                        </td>
                      </tr>
                      <!-- Price per credit from package -->
                      <tr>
                        <td> {{ $t( "creditAndCostCalculator.creditCalculator.pricePerCredit")}} (EUR)</td>
                        <td v-for="(creditPackage, creditPackageIndex) in this.packages" :key="creditPackageIndex">
                          {{ creditPackage.pricePerCredit.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                        </td>
                      </tr>
                      <!-- Price per interview -->
                      <tr>
                        <td> {{ $t( "creditAndCostCalculator.creditCalculator.pricePerRespondent")}} (EUR)</td>
                        <td v-for="(creditPackage, creditPackageIndex) in this.packages" :key="creditPackageIndex">
                          {{ creditPackage.pricePerInterview.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                        </td>
                      </tr>
                      <!-- Price of the Package -->
                      <tr>
                        <td> {{ $t( "creditAndCostCalculator.creditCalculator.pricePerPackage")}} (EUR)</td>
                        <td v-for="(creditPackage, creditPackageIndex) in this.packages" :key="creditPackageIndex">
                          {{ creditPackage.priceOfPackage.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                        </td>
                      </tr>
                      <!-- Additional Payment -->
                      <tr>
                        <td> {{ $t( "creditAndCostCalculator.creditCalculator.additionalPayment")}} (EUR)</td>
                        <td v-for="(creditPackage, creditPackageIndex) in this.packages" :key="creditPackageIndex">
                          <span v-if="creditPackage.addtionalPayment > 0" >
                            {{ creditPackage.addtionalPayment.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                          </span>
                          <span v-else>
                            -
                          </span>
                        </td>
                      </tr>
                      <!-- Annual Sum -->
                      <tr style="background-color: aliceblue">
                        <td> {{ $t("creditAndCostCalculator.creditCalculator.totalCostPerYear")}} (EUR)</td>
                        <td v-for="(creditPackage, creditPackageIndex) in this.packages" :key="creditPackageIndex">
                          <strong> {{ creditPackage.totalYearlyAmout.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> 
            </div>
          </div>
        </div>

        <b-button
          v-show="!displayYourSituation"
          variant="success"
          class="btn-block p-2"
          @click="clickDisplayYourSituation()"
          :disabled="!this.oneOfThePackagesSelected"
        >
          {{ $t("creditAndCostCalculator.creditCalculator.continueButton") }}
        </b-button>
      </div>
    </div>

    <!-- Your Situation -->
    <div class="row justify-content-center" v-if="displayYourSituation">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              {{ $t("creditAndCostCalculator.currentSituation.title") }}
            </div>

            <!-- Number of Projects Per Year -->
            <div class="mt-3 row align-items-center">
              <div class="col-9">
                <label>
                  {{
                    $t(
                      "creditAndCostCalculator.currentSituation.numberofProjectsPerYear.label"
                    )
                  }}
                </label>
              </div>
              <div class="col-3">
                <b-form-input
                  v-model.number="numberOfProjectsPlanedPerYear"
                  type="number"
                  :placeholder="
                    $t(
                      'creditAndCostCalculator.currentSituation.numberofProjectsPerYear.placeholder'
                    )
                  "
                  :disabled="displayROI"
                  :style="[
                    displayROI
                      ? { background: '#f5f5f5' }
                      : { background: '#fff' }
                  ]"
                >
                </b-form-input>
              </div>
            </div>

            <!-- time spent per project -->
            <div class="mt-3 row align-items-center">
              <div class="col-9">
                <label>
                  {{
                    $t(
                      "creditAndCostCalculator.currentSituation.timeToSpent.label"
                    )
                  }}
                  <span>
                    <i
                      v-b-popover.hover="propoverMethodTimeSpentForEvaluation"
                      class="ml-1 bx bxs-info-circle text-info"
                      style="cursor: pointer"
                    ></i
                  ></span>
                </label>
              </div>
              <div class="col-3">
                <b-form-input
                  v-model.number="numberOfHoursSpentPerProject"
                  type="number"
                  :placeholder="
                    $t(
                      'creditAndCostCalculator.currentSituation.timeToSpent.placeholder'
                    )
                  "
                  :disabled="displayROI"
                  :style="[
                    displayROI
                      ? { background: '#f5f5f5' }
                      : { background: '#fff' }
                  ]"
                >
                </b-form-input>
              </div>
            </div>

            <!-- estimated cost per hour -->
            <div class="mt-3 row align-items-center">
              <div class="col-9">
                <label>
                  {{
                    $t(
                      "creditAndCostCalculator.currentSituation.costPerHour.label"
                    )
                  }}
                  <span>
                    <i
                      v-b-popover.hover="propoverMethodEstimatedCostPerHour"
                      class="ml-1 bx bxs-info-circle text-info"
                      style="cursor: pointer"
                    ></i
                  ></span>
                </label>
              </div>
              <div class="col-3">
                <b-form-input
                  v-model.number="costPerHour"
                  type="number"
                  :placeholder="
                    $t(
                      'creditAndCostCalculator.currentSituation.costPerHour.placeholder'
                    )
                  "
                  :disabled="displayROI"
                  :style="[
                    displayROI
                      ? { background: '#f5f5f5' }
                      : { background: '#fff' }
                  ]"
                >
                </b-form-input>
              </div>
            </div>
          </div>
        </div>

        <b-button
          v-show="!displayROI"
          variant="success"
          class="btn-block p-2"
          @click="clickDisplayROI()"
          :disabled="
            !numberOfProjectsPlanedPerYear ||
            !numberOfHoursSpentPerProject ||
            !costPerHour
          "
        >
          {{
            $t("creditAndCostCalculator.currentSituation.calculateCostButton")
          }}
        </b-button>
      </div>
    </div>

    <!-- ROI -->
    <div class="row justify-content-center">
      <div class="col-lg-4" v-show="displayROI">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="row mb-3 align-content-center">
                <div class="col-7">
                  <h5>
                    <i class="bx bx-money h4 text-warning bx-tada mr-1"></i>
                    {{ $t("creditAndCostCalculator.costSaveSummary.title") }}
                  </h5>
                </div>
                <div class="col-5 text-right">
                  <b-button variant="dark" @click="clickHideROI()" size="sm">
                    {{
                      $t("creditAndCostCalculator.costSaveSummary.editButton")
                    }}
                  </b-button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                {{ $t("creditAndCostCalculator.costSaveSummary.description") }}
              </div>
            </div>

            <table class="table mt-3 mb-5">
              <thead>
                <tr>
                  <th></th>
                  <th class="text-right">
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.table.projectCost"
                      )
                    }}
                  </th>
                  <th class="text-right">
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.table.yearlyCost"
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Current Situation -->
                <tr>
                  <td>
                    <label>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.table.currentSituation"
                        )
                      }}
                    </label>
                  </td>
                  <td class="text-right">
                    {{
                      internalCostPerProject.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      totalInternalCostPerYear.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </td>
                </tr>

                <!-- Redem Solution-->
                <tr>
                  <td>
                    <span class="logo-lg">
                      <img
                        src="@/assets/images/Redem-Logo.png"
                        alt
                        height="25"
                      />
                    </span>
                  </td>
                  <td class="text-right">
                    {{
                      redemCostPerProject.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      totalRedemCostPerYear.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </td>
                </tr>

                <!-- Cost Saving -->
                <tr>
                  <td>
                    <label>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.table.costsaving"
                        )
                      }}
                      <b-badge
                        v-if="costSavingPerYearPercentage > 0"
                        class="ml-2 badge-soft-success"
                        style="font-size: 15px"
                      >
                        <strong>
                          {{
                            costSavingPerYearPercentage.toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                              }
                            )
                          }}
                          %
                        </strong>
                      </b-badge>
                    </label>
                  </td>
                  <td class="text-right">
                    <strong v-if="costSavingPerProject > 0">
                      {{
                        costSavingPerProject.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}
                    </strong>
                    <span v-else> 0 </span>
                  </td>
                  <td class="text-right">
                    <strong v-if="costSavingPerYear > 0">
                      {{
                        costSavingPerYear.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}
                    </strong>
                    <span v-else> 0 </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="card-title mt-5">
              <div class="row">
                <div class="col-12">
                  <h5>
                    <i class="bx bx-diamond h4 text-success bx-tada mr-1"></i>
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.benefits.title"
                      )
                    }}
                  </h5>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <ul>
                  <li class="mb-2">
                    <strong>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.benefits.point1.bold"
                        )
                      }}
                    </strong>
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.benefits.point1.normal"
                      )
                    }}
                  </li>
                  <li class="mb-2">
                    <strong>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.benefits.point2.bold"
                        )
                      }}
                    </strong>
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.benefits.point2.normal"
                      )
                    }}
                  </li>
                  <li class="mb-2">
                    <strong>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.benefits.point3.bold"
                        )
                      }}
                    </strong>
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.benefits.point3.normal"
                      )
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <b-button
          v-show="displayROI"
          variant="primary"
          class="btn-block p-2"
          @click="clickMailToButton()"
        >
          {{$t("creditAndCostCalculator.requestOfferButton")}}
        </b-button>
      </div>
    </div>

    <!-- Leagual Sentence -->
    <div class="row justify-content-center text-center mt-3">
      <div class="col-md-4">
        {{ $t("creditAndCostCalculator.legalSentence") }}
      </div>
    </div>

    <!-- language Selector -->
    <div class="mt-3 text-center">
      <span
        class="mr-2"
        @click="setLanguage(languages[0].language)"
        style="cursor: pointer"
      >
        <img class="mr-1" :src="languages[0].flag" height="10" />
        {{ languages[0].title }}
      </span>
      &bull;
      <span
        class="ml-2"
        @click="setLanguage(languages[1].language)"
        style="cursor: pointer"
      >
        <img class="mr-1" :src="languages[1].flag" height="10" />
        {{ languages[1].title }}
      </span>
    </div>
  </div>
</template>

<style scoped>
table,
th,
td {
  border: 1px solid #f5f5f5;
}
</style>